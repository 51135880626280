/** @jsx jsx */
/* 3rd party imports */
import { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { jsx, useColorMode } from 'theme-ui';
import { Button, Flex } from '@theme-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { faMoon as faMoonLine } from '@fortawesome/free-regular-svg-icons';

/* 1st party imports */
import Styles from '@/components/Header/styles';

const LinkSpacer: FunctionComponent = () => ( <span sx={{cursor: 'default', color: 'layoutLinkHover'}}>|</span> );

const Component: FunctionComponent = () => {
	const [colorMode, setColorMode] = useColorMode<'default' | 'dark'>('default');

	return (
		<header sx={Styles.header}>
			<nav sx={Styles.pageLinks}>
				<Link to="/">Home</Link>
				<LinkSpacer/>
				{/*<Link to="/gallery">Gallery</Link>
				<LinkSpacer/>*/}
				{/* <Link to="/posts">Posts</Link>
				<LinkSpacer/> */}
				<Link to="/contact">Contact</Link>
			</nav>
			<Flex sx={Styles.darkMode}>
				<Button variant="header" onClick={() => setColorMode(colorMode === 'default' ? 'dark' : 'default')}>
					<FontAwesomeIcon icon={colorMode === 'default' ? faMoonLine : faMoon}/>
				</Button>
			</Flex>
		</header>
	);
};

export default Component;